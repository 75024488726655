import { useNavigate } from "react-router-dom";
import { EventDto } from "../../models/events/EventDto";
import { Swiper, SwiperSlide } from "swiper/react";
import UpcomingEvent from "../events/UpcomingEvent";
import { Autoplay, Pagination, Navigation } from "swiper/modules"

interface PortfolioProps {
  completedEvents: EventDto[];
}

const Portfolio: React.FC<PortfolioProps> = ({ completedEvents }) => {
  const navigate = useNavigate();
  return (
    <section className="section bg-secondary-dark" style={{ color: 'white' }}>
      <div className="r-container">
        <div className="d-flex flex-column ">
          <h5 className="accent-color lh-1 ls-2 fw-bold font-1">Portfolio</h5>
          <h3 className="lh-1 fw-bold" style={{ fontSize: '48px' }}>Elevating Experiences, Every Event</h3>

          <div className="row row-cols-1 row-cols-lg-2 my-3">
            <div className="col">
              <div className='descriptions'>
                At YO Entertainment, we excel in creating bespoke experiences designed around your distinct vision. Fueled by boundless creativity and a dedication to perfection, our team transforms concepts into reality with an unwavering focus on detail and unmatched proficiency. From enchanting event design to strategic marketing initiatives and flawless coordination, we are committed to delivering outcomes that surpass expectations.`Be it a corporate affair, a joyous gathering, or a cultural spectacle, YO Entertainment is devoted to elevating each occasion, crafting memories that endure. Allow us to bring your aspirations to life and ensure your next event is an extraordinary triumph.
              </div>
              <div className="d-flex flex-lg-row flex-column gap-lg-5 gap-3 py-3">
                <button onClick={() => navigate('/events')}
                  className="px-4 py-2 btn button accent-color button-outline rounded-0 fw-bold font-1 hover-transform see-all-events-button">
                  See all events
                </button>
              </div>
            </div>
            <div className="col">
              <div className="d-flex flex-column">
                <div className="col">
                  <div className="overflow-hidden d-flex flex-column gap-5">
                    <div className="d-flex flex-column">
                      <div className="col">
                        <Swiper
                          breakpoints={{
                            640: { slidesPerView: 1, spaceBetween: 15 },
                            768: { slidesPerView: 2, spaceBetween: 15 },
                            1024: { slidesPerView: 2, spaceBetween: 15 }
                          }}
                          loop={true}
                          pagination={{ clickable: true  }}
                          style={{ width: '100%', color: 'white' }}
                          modules={[Autoplay, Pagination, Navigation]}
                          autoplay={{ delay: 3000, disableOnInteraction: false }}>
                          {
                            completedEvents.map((event, index) => (
                              <SwiperSlide key={index}>
                                <UpcomingEvent event={event} />
                              </SwiperSlide>
                            ))
                          }
                        </Swiper>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Portfolio;