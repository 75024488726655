import { CreateTicketDto } from "../models/tickets/CreateTicketDto";
import {
  maxLength,
  minLength,
  isNotEmpty,
  isEmail,
  isMobilePhone,
  isNumber,
} from "class-validator";
import { VALIDATION_MESSAGES } from "../constants";
import { FiledValidationInfo } from "../models/validations/FiledValidationInfo";
import { ReservationValidationState } from "../models/validations/ReservationValidationState";

export const validateReservationData = (
  reservation: CreateTicketDto
): ReservationValidationState => {
  let state: ReservationValidationState = new ReservationValidationState();

  // ticketTypeId: FiledValidationInfo;
  // quantity: FiledValidationInfo;
  // name: FiledValidationInfo;
  // email: FiledValidationInfo;
  // phone: FiledValidationInfo;
  // address: FiledValidationInfo;
  // identificationField: FiledValidationInfo;

  state.name = validateNames(`${reservation.customer.name}`);
  state.email = validateEmail(`${reservation.customer.email}`);
  state.phone = validatePhone(`${reservation.customer.phone}`);
  state.ticketTypeId = validateTicketTypeId(reservation.ticketTypeId);
  state.identificationField = validateIdentityField(reservation.customer.identificationField);
  state.quantity = validateQuantity(reservation.quantity);

  console.log('Ticker validation status: ', state);

  state.status = 
    state.name.status && 
    state.email.status && 
    state.phone.status && 
    state.ticketTypeId.status && 
    state.identificationField.status && 
    state.quantity.status;

  return state;
};

const validateNames = (name: string): FiledValidationInfo => {
  let descriptions: string[] = [];
  let status = true;
  // eslint-disable-next-line eqeqeq
  if (name == undefined || name == null || !isNotEmpty(name)) {
    status = false;
    descriptions.push(VALIDATION_MESSAGES.NAME_NOT_EMPTY);
  }
  if (!minLength(name, 2)) {
    status = false;
    descriptions.push(VALIDATION_MESSAGES.NAME_MIN_LENGTH);
  }
  if (!maxLength(name, 256)) {
    status = false;
    descriptions.push(VALIDATION_MESSAGES.NAME_MAX_LENGTH);
  }
  
  const description = `Field ${descriptions.join(", ")}.`;
  return { status, description };
};

const validateTicketTypeId = (ticketTypeId: number): FiledValidationInfo => {
  let status = ticketTypeId > 0 && isNumber(ticketTypeId);
  let description = status ? "" : VALIDATION_MESSAGES.TICKET_TYPE_ID_NOT_VALID;
  return { status, description };
}

const validateEmail = (email: string): FiledValidationInfo => {
  let status = isEmail(email);
  let description = status ? "" : VALIDATION_MESSAGES.EMAIL_NOT_VALID;
  return { status, description };
};

const validatePhone = (phone: string): FiledValidationInfo => {
  const lKStatus = isMobilePhone(phone, "si-LK");
  const cadStatus = isMobilePhone(phone, "en-CA");
  let description = lKStatus ||  cadStatus ? "" : VALIDATION_MESSAGES.PHONE_NOT_VALID;
  return { status: (lKStatus || cadStatus), description };
};

const validateIdentityField = (identityField: string): FiledValidationInfo => {
  let status = isNotEmpty(identityField);
  let description = status ? "" : VALIDATION_MESSAGES.IDENTITY_FIELD_NOT_VALID;
  return { status, description };
}

const validateQuantity = (quantity: number): FiledValidationInfo => {
  let status = quantity >= 1 && isNumber(quantity);
  let description = status ? "" : VALIDATION_MESSAGES.QUANTITY_NOT_VALID;
  return { status, description };
}